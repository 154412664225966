import React, { useState } from 'react';
import { Form, Row, Col, Select, Input, Button, message, Table, Card, Tag, Descriptions, Divider, Modal } from 'antd';
import { Fine, State, States } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../utils/hooks';
import { SearchOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
const server = process.env.REACT_APP_SERVER_URL;

const ConsultFines: React.FC<ConsultFinesProps> = ({ auth, thm }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [searching, setSearching] = useState(false);
  const [fines, setFines] = useState<Fine[]>([]);
  const [visible, setVisible] = useState(false);
  const [selectedPenalty, setSelectedPenalty] = useState<Fine | null>();

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const StatusConfig: colorStatus = {
    validando: { color: 'warning', name: 'Validando pago' },
    ingresardatos: { color: 'cyan', name: 'En espera de pago' },
    finalizado: { color: 'green', name: 'Pagada' },
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Código Multa',
      dataIndex: 'codigoMulta',
      align: 'center',
    },
    {
      title: 'Infractor / VH',
      dataIndex: 'cedula',
      align: 'center',
      render: (cedula: string, record) => (/^\d+$/.test(cedula) ? `${record.nacionalidad}-${cedula}` : cedula),
    },
    {
      title: 'Placa de Vehículo',
      dataIndex: 'placa',
      align: 'center',
      render: (placa, record) => placa?.toUpperCase() || record?.datos?.funcionario?.placa?.toUpperCase() || 'N/A',
    },
    {
      title: 'Fecha de Asignación',
      dataIndex: 'fechaCreacion',
      align: 'center',
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      align: 'center',
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={StatusConfig[value].color}>
          {StatusConfig[value].name}
        </Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <Button
            type='primary'
            icon={<EyeOutlined />}
            onClick={() => {
              setVisible(true);
              setSelectedPenalty(fines.find((f) => f.id === id));
            }}
          >
            Ver Detalles
          </Button>
        );
      },
    },
  ];

  const search = async () => {
    const values = await form.validateFields();
    try {
      const _tipoDocumento = /^\d+$/.test(values?.documento ?? 'null') ? tipoDocumento : 'V';
      setSearching(true);
      const response = await axios.get(
        `${server}/fines/instances/usuario/usuario?documento=${values.documento}&tipoDocumento=${_tipoDocumento}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setFines(response.data.fines);
    } catch (e) {
      message.error(e?.response?.data?.message || 'Error al buscar contribuyente');
    } finally {
      setSearching(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Consulta Multa'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Modal
        footer={null}
        visible={visible}
        title='Detalles de la multa'
        onCancel={() => {
          setVisible(false);
          setSelectedPenalty(null);
        }}
      >
        <Descriptions column={2} title='Datos del infractor'>
          <Descriptions.Item label='Documento'>
            {/^\d+$/.test(selectedPenalty?.cedula ?? 'null')
              ? `${selectedPenalty?.datos?.funcionario?.nacionalidad}-${selectedPenalty?.datos?.funcionario?.cedula}`
              : 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Nombre'>
            {selectedPenalty?.datos?.funcionario?.nombreCompleto ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Fecha de Nacimiento'>
            {selectedPenalty?.datos?.funcionario?.fechaNacimiento
              ? moment(selectedPenalty?.datos?.funcionario?.fechaNacimiento).format('DD-MM-YYYY')
              : 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Correo Electrónico'>
            {selectedPenalty?.datos?.funcionario?.correo ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Teléfono Fijo'>
            {selectedPenalty?.datos?.funcionario?.telefono ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Teléfono Movil'>
            {selectedPenalty?.datos?.funcionario?.telefonoMovil ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Tipo de Licencia'>
            {selectedPenalty?.datos?.funcionario?.tipoLicencia ?? 'NO APLICA'}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label='Dirección'>{selectedPenalty?.datos?.funcionario?.direccion ?? 'NO APLICA'}</Descriptions.Item>
        </Descriptions>
        <Divider />

        <Descriptions column={1} title='Datos de la infracción'>
          {selectedPenalty?.datos?.funcionario?.infraccion.map((f) => (
            <>
              <Descriptions.Item label='Gaceta'>{f?.gaceta?.descripcion ?? 'NO APLICA'}</Descriptions.Item>
              <Descriptions.Item label='Articulo'>{f?.articulo?.articulo ?? 'NO APLICA'}</Descriptions.Item>
              <Descriptions.Item label='Numeral'>{f?.numeral?.descripcion ?? 'NO APLICA'}</Descriptions.Item>
              <Descriptions.Item style={{ margin: '24px', borderBottom: '1px solid #f0f0f0' }} label='Literal'>
                {f?.literal?.descripcion ?? 'NO APLICA'}
              </Descriptions.Item>
            </>
          ))}
        </Descriptions>

        <Descriptions column={2}>
          <Descriptions.Item label='Lugar'>{selectedPenalty?.datos?.funcionario?.lugar ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Parroquia'>{selectedPenalty?.datos?.funcionario?.parroquia ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Fecha y Hora'>
            {moment(selectedPenalty?.datos?.funcionario?.fechaHora).format('DD-MM-YYYY hh:mm A') ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Detalles'>{selectedPenalty?.datos?.funcionario?.detalle ?? 'NO APLICA'}</Descriptions.Item>
        </Descriptions>

        <Descriptions column={4} title='Datos del Vehiculo'>
          <Descriptions.Item label='Placa'>{selectedPenalty?.datos?.funcionario?.placa ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Marca'>{selectedPenalty?.datos?.funcionario?.marca ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Modelo'>{selectedPenalty?.datos?.funcionario?.modelo ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Año'>{selectedPenalty?.datos?.funcionario?.ano ?? 'NO APLICA'}</Descriptions.Item>
        </Descriptions>
        {selectedPenalty?.soporte && selectedPenalty?.soporte?.length > 0 && (
          <Descriptions column={2} title={'Imágenes de Soporte'}>
            {selectedPenalty?.soporte?.map((s) => (
              <Descriptions.Item>
                <Card
                  hoverable
                  bodyStyle={{ height: 0 }}
                  style={{ width: 150, height: 150 }}
                  cover={<img src={s} alt='Imagen de soporte multa' />}
                  actions={[
                    <a href={s}>
                      Descargar <DownloadOutlined />
                    </a>,
                  ]}
                ></Card>
              </Descriptions.Item>
            ))}
          </Descriptions>
        )}
      </Modal>
      <Form layout='vertical' form={form}>
        <Row gutter={24}>
          <Col xs={24} xl={12}>
            <Form.Item
              label='Documento de Identidad / Placa del Vehículo'
              name='documento'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad o placa del vehículo' }]}
            >
              <Input placeholder='Documento de Identidad / Placa del Vehículo' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xl={3} xs={12}>
            <Button
              onClick={() => search()}
              style={{ marginTop: width < 1200 ? 0 : 38, width: '100%' }}
              loading={searching}
              icon={<SearchOutlined />}
              type='primary'
            >
              Buscar
            </Button>
          </Col>
          <Col span={24}>
            <Table
              style={{ marginTop: width < 1200 ? 38 : 0 }}
              columns={columns}
              dataSource={fines}
              rowKey='id'
              bordered
              loading={searching}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm });

export default connect(mapStateToProps)(ConsultFines);

interface ConsultFinesProps {
  auth: States.Auth;
  thm: States.ThemeColors;
}

declare type StatusProcedure = 'Validando pago' | 'En espera de pago' | 'Pagada';

interface colorStatus {
  [status: string]: {
    name: StatusProcedure;
    color: string;
  };
}
