import React, { useState, useRef, useEffect } from 'react';
import { Table, Tag, Button, Input, Tooltip, Row, Col, DatePicker, Modal, Descriptions, Divider, Card, message } from 'antd';
import { State, States, Fine } from 'sigt';
import { connect } from 'react-redux';
import { useQuery } from '../../utils/hooks';
import { ColumnsType } from 'antd/lib/table';
import { setFine, changeFineStatus, changeFineCertificate } from '../../redux/actions/procedures';
import moment, { Moment } from 'moment';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router';
import { SearchOutlined, CalendarOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { getFineCertificate } from '../../services/fines';

const TablePenalty: React.FC<TablePenaltyProps> = ({ thm, fines, auth }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedPenalty, setSelectedPenalty] = useState<Fine | null>();
  const inputRef = useRef<any>(null);
  const history = useHistory();
  const queryParams = useQuery();
  const codigoMulta = queryParams.get('codigoMulta') ? queryParams.get('codigoMulta') : '';
  const [loading, setLoading] = useState(true);
  const [keys, setKeys] = useState<Moment[]>([]);

  const StatusConfig: colorStatus = {
    validando: { color: 'warning', name: 'Validando pago' },
    ingresardatos: { color: 'cyan', name: 'En espera de pago' },
    finalizado: { color: 'green', name: 'Pagada' },
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getFile = async (record: Fine) => {
    try {
      setDisabled(true);
      let response = await getFineCertificate(auth.token, record.id);

      if (Array.isArray(response.url)) {
        response.url.forEach((cert) => {
          window.open(cert, '_blank');
          window?.focus();
        });
      } else {
        const win = window.open(response.url, '_blank');
        win?.focus();
      }
    } catch (error) {
      console.log('getFile', error);
      message.error('Error en el servidor');
    } finally {
      setDisabled(false);
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]?.toString()?.toLowerCase() ?? '').includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
        <Row style={{ width: 250, padding: 8 }} gutter={[8, 8]}>
          <Col span={24}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format='DD/MM/YYYY'
              placeholder={['Fecha inicio ', 'Fecha fin']}
              size='small'
              onChange={(values) => {
                setSelectedKeys(values);
              }}
              value={selectedKeys}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<SearchOutlined />}
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Buscar
            </Button>
          </Col>
          <Col span={12}>
            <Button style={{ width: '100%' }} onClick={() => clearFilters()} size='small'>
              Restablecer
            </Button>
          </Col>
        </Row>
      );
    },
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => {
      return (
        moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') ||
        moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
      );
    },
  });

  const columns: ColumnsType<Fine> = [
    {
      title: 'Código',
      dataIndex: 'codigoMulta',
      align: 'center',
      ...getColumnSearchProps('codigoMulta'),
    },
    auth.user?.tipoUsuario === 4
      ? {
          title: 'Institución',
          dataIndex: 'nombreCorto',
          filters: [
            { value: 'PMM', text: 'PoliMaracaibo' },
            { value: 'IMA', text: 'Instituto de Ambiente' },
          ],
          onFilter: (value, record) => record.nombreCorto.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
          align: 'center',
          render: (text, record) => <Tooltip title={record.nombreLargo}>{text}</Tooltip>,
        }
      : {
          title: 'Infractor / VH',
          dataIndex: 'cedula',
          align: 'center',
          ...getColumnSearchProps('cedula'),
          render: (cedula: string, record) => (/^\d+$/.test(cedula) ? `${record.nacionalidad}-${cedula}` : cedula),
        },
    {
      title: 'Fecha de Asignación',
      dataIndex: 'fechaCreacion',
      align: 'center',
      ...getColumnFilterDate('fechaCreacion'),
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      align: 'center',
      filters: [
        { value: 'validando', text: 'Validando pago' },
        { value: 'ingresardatos', text: 'En espera de pago' },
        { value: 'finalizado', text: 'Finalizado' },
      ],
      onFilter: (value, record) => record.estado.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={StatusConfig[value].color}>
          {StatusConfig[value].name}
        </Tag>
      ),
    },
  ];

  if (auth.user?.institucion?.id === 7) {
    columns.splice(2, 0, {
      title: 'Placa de Vehículo',
      dataIndex: 'placa',
      align: 'center',
      ...getColumnSearchProps('placa'),
      render: (placa: string) => (placa ? placa.toUpperCase() : 'N/A'),
    });
    columns.splice(3, 0, {
      title: 'Funcionario',
      dataIndex: 'funcionario',
      ...getColumnSearchProps('funcionario'),
    });
    columns.push({
      title: '',
      dataIndex: 'id',
      render: (id, record) => {
        if (record?.estado !== 'finalizado')
          return (
            <Button
              disabled={disabled}
              type='primary'
              icon={<EyeOutlined />}
              onClick={() => {
                setVisible(true);
                setSelectedPenalty(fines.find((f) => f.id === id));
              }}
            >
              Ver Detalles
            </Button>
          );
        if (record?.estado === 'finalizado')
          return (
            <Button disabled={disabled} type='primary' icon={<EyeOutlined />} onClick={() => getFile(record)}>
              Constancia
            </Button>
          );
      },
    });
  }

  const renderAction = (fine: Fine) =>
    fine.estado === 'ingresardatos' ? (
      <Button onClick={() => history.push(`/dashboard/pagarMulta/${fine.id}`)}>Pagar</Button>
    ) : fine.estado === 'finalizado' ? (
      <Button target='_blank' href={fine.certificado || ''}>
        Solvencia
      </Button>
    ) : null;

  if (auth.user?.tipoUsuario === 4 && fines.find((e) => e.estado === 'ingresardatos' || e.estado === 'finalizado')) {
    columns.push({
      title: 'Acciones',
      dataIndex: 'acciones',
      fixed: 'right',
      align: 'center',
      width: 50,
      render: (_, record) => renderAction(record),
    });
  }
  const selectedRow = (record) => {
    const id = parseInt(codigoMulta ? codigoMulta : '');
    return record.id === id ? 'filterRow' : '';
  };
  useEffect(() => {
    setLoading(false);
    // setLoading(fines.length > 0 ? false : true)
  }, [fines]);

  return (
    <>
      <Table
        loading={loading}
        rowClassName={(record) => selectedRow(record)}
        pagination={{ pageSize: 10 }}
        size='middle'
        columns={columns}
        dataSource={fines.map((f) => ({
          ...f,
          placa: f.datos.funcionario?.placa,
          funcionario: f.datos.funcionario?.nombreCompletoFuncionario,
        }))}
        rowKey={(e) => e.id}
      />
      <Modal
        footer={null}
        visible={visible}
        title='Detalles de la multa'
        onCancel={() => {
          setVisible(false);
          setSelectedPenalty(null);
        }}
      >
        <Descriptions column={2} title='Datos del infractor'>
          <Descriptions.Item label='Documento'>
            {/^\d+$/.test(selectedPenalty?.cedula ?? 'null')
              ? `${selectedPenalty?.datos?.funcionario?.nacionalidad}-${selectedPenalty?.datos?.funcionario?.cedula}`
              : 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Nombre'>
            {selectedPenalty?.datos?.funcionario?.nombreCompleto ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Fecha de Nacimiento'>
            {selectedPenalty?.datos?.funcionario?.fechaNacimiento
              ? moment(selectedPenalty?.datos?.funcionario?.fechaNacimiento).format('DD-MM-YYYY')
              : 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Correo Electrónico'>
            {selectedPenalty?.datos?.funcionario?.correo ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Teléfono Fijo'>
            {selectedPenalty?.datos?.funcionario?.telefono ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Teléfono Movil'>
            {selectedPenalty?.datos?.funcionario?.telefonoMovil ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Tipo de Licencia'>
            {selectedPenalty?.datos?.funcionario?.tipoLicencia ?? 'NO APLICA'}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label='Dirección'>{selectedPenalty?.datos?.funcionario?.direccion ?? 'NO APLICA'}</Descriptions.Item>
        </Descriptions>
        <Divider />

        <Descriptions column={1} title='Datos de la infracción'>
          {selectedPenalty?.datos?.funcionario?.infraccion.map((f) => (
            <>
              <Descriptions.Item label='Gaceta'>{f?.gaceta?.descripcion ?? 'NO APLICA'}</Descriptions.Item>
              <Descriptions.Item label='Articulo'>{f?.articulo?.articulo ?? 'NO APLICA'}</Descriptions.Item>
              <Descriptions.Item label='Numeral'>{f?.numeral?.descripcion ?? 'NO APLICA'}</Descriptions.Item>
              <Descriptions.Item style={{ margin: '24px', borderBottom: '1px solid #f0f0f0' }} label='Literal'>
                {f?.literal?.descripcion ?? 'NO APLICA'}
              </Descriptions.Item>
            </>
          ))}
        </Descriptions>

        <Descriptions column={2}>
          <Descriptions.Item label='Lugar'>{selectedPenalty?.datos?.funcionario?.lugar ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Parroquia'>{selectedPenalty?.datos?.funcionario?.parroquia ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Fecha y Hora'>
            {moment(selectedPenalty?.datos?.funcionario?.fechaHora).format('DD-MM-YYYY hh:mm A') ?? 'NO APLICA'}
          </Descriptions.Item>
          <Descriptions.Item label='Detalles'>{selectedPenalty?.datos?.funcionario?.detalle ?? 'NO APLICA'}</Descriptions.Item>
        </Descriptions>

        <Descriptions column={4} title='Datos del Vehiculo'>
          <Descriptions.Item label='Placa'>{selectedPenalty?.datos?.funcionario?.placa ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Marca'>{selectedPenalty?.datos?.funcionario?.marca ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Modelo'>{selectedPenalty?.datos?.funcionario?.modelo ?? 'NO APLICA'}</Descriptions.Item>
          <Descriptions.Item label='Año'>{selectedPenalty?.datos?.funcionario?.ano ?? 'NO APLICA'}</Descriptions.Item>
        </Descriptions>
        {selectedPenalty?.soporte && selectedPenalty?.soporte?.length > 0 && (
          <Descriptions column={2} title={'Imágenes de Soporte'}>
            {selectedPenalty?.soporte?.map((s) => (
              <Descriptions.Item>
                <Card
                  hoverable
                  bodyStyle={{ height: 0 }}
                  style={{ width: 150, height: 150 }}
                  cover={<img src={s} alt='Imagen de soporte multa' />}
                  actions={[
                    <a href={s}>
                      Descargar <DownloadOutlined />
                    </a>,
                  ]}
                ></Card>
              </Descriptions.Item>
            ))}
          </Descriptions>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, fines: state.prcd.fines, auth: state.auth });

export default connect(mapStateToProps, { setFine, changeFineStatus, changeFineCertificate })(TablePenalty);

interface TablePenaltyProps {
  thm: States.ThemeColors;
  fines: States.Procedures['fines'];
  auth: States.Auth;
}

interface colorStatus {
  [status: string]: {
    name: StatusProcedure;
    color: string;
  };
}

declare type StatusProcedure = 'Validando pago' | 'En espera de pago' | 'Pagada';
